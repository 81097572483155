@font-face {
  font-family: 'PhoenixSans-Regular';
  src: url('./assets/fonts/PhoenixSans-Regular.woff') format("woff"),url('./assets/fonts/PhoenixSans-Regular.ttf') format("truetype");
  font-weight: normal;
  font-display: swap
}

@font-face {
  font-family: 'PhoenixSans-Medium';
  src: url('./assets/fonts/PhoenixSans-Medium.woff') format("woff"),url('./assets/fonts/PhoenixSans-Medium.ttf') format("truetype");
  font-weight: 500;
  font-display: swap
}

@font-face {
  font-family: 'PhoenixSans-Bold';
  src: url('./assets/fonts/PhoenixSans-Bold.woff') format("woff"),url('./assets/fonts/PhoenixSans-Bold.ttf') format("truetype");
  font-weight: bold;
  font-display: swap
}


body {
  margin: 0;
  color:#331f37;
  font-family: 'PhoenixSans-Regular',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow:hidden;
  height: 100dvh;
}

#root {
  z-index:10;
}

h1,h2,h3,h4,h5 {
  font-family: 'PhoenixSans-Bold',
  sans-serif;
}

#map-holder {
position:relative;
}

#map-container2 {
  /* display:none; */
}

#map-container, #map-container1 , #map-container2 {
  
  animation: fadeIn 1s;
  /* animation-delay: 2s; */
  -webkit-animation: fadeIn 1s;
  -moz-animation: fadeIn 1s;
  -o-animation: fadeIn 1s;
  -ms-animation: fadeIn 1s;
}

#map-container1 {
  position: absolute;
  top:22px;
  left:0px;
}

@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  display:flex;
  overflow: hidden;
}

.main-logo {
  width: 200px;
  padding-top:2rem;
  padding-bottom:2rem;
}


#side-panel {
  /* position: absolute; */
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  /* overflow-y: scroll;*/
  /* -webkit-overflow-scrolling: touch;  */
  padding: 0 2rem;
  /* width:330px; */
  width:188px;
  /* padding-top:2rem; */
  z-index:2;
  left:0;
  transition: left 0.4s ease-out;
  background-color: #331f37;
  color: white;
  text-align:center;

}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  /* webkit specific CSS */
  #side-panel{ height:100svh; }
}



/* #side-panel:after {
  content: '';
  position: absolute;
  width:50px;
  height:50px;
  right:-50px;
  top:50%;
  background-color:#ff455d;
} */

#nav-toggle {
  /* content: ''; */
  position: absolute;
  display:flex;
  justify-content: center;
  align-items: center;
  width:150px;
  height:50px;
  right:-100px;
  top:50%;
  background-color:#ff455d;
  cursor: pointer;
  transform: rotate(-90deg) translateX(17%);
  font-family: 'PhoenixSans-Bold', sans-serif;
  font-size: 1.2rem;
  border-radius: 0 0 4px 4px;
}

/* #nav-toggle:hover {
  background:#e36877 ;
} */

 #side-panel > h2,  #side-panel > button {
  margin: 1rem 2rem;
}

#map-holder {
  width:100%;
}

.mapRegions {
  /* position: absolute; */
  /* right:-200px; */
  overflow: initial;
  height:100vh;
}

#map-container {
  position:absolute;
  right:0;
  /* width: calc(100% - 250px); */
  width: 100%;
  height: 100vh;
  transition: width 0.4s ease-out;
}

.logo-dark {
  position: absolute;
  left: -260px;
  width: 200px;
  padding-top:2rem;
  padding-bottom:2rem;
  padding:2rem 2rem;
  top: 0px;
  z-index:1;
}

.question-display {
  position: absolute;
  left: 50px;
  padding:2rem 2rem;
  top: 98px;
  z-index:1;
  width:50%;
  min-width:225px;
  max-width:600px;
  background-color: #ffffffdd;
  /* background-color: #fff4ecdd; */
  border-radius: 4px;
  transition: left 0.4s ease-out;
  opacity:0;
  /* border: 1px solid #ff455d; */
  
}

@media only screen and (max-width: 400px) {
  .question-display {
    min-width:185px;
  }
}

.sidepanel-questions {
  /* width:200px; */
  /* overflow-y: auto; */
  /* overflow-y: scroll; */
  overflow-x:hidden;
  /* -webkit-overflow-scrolling: touch; */
  padding-right:14px;
  padding-left:14px;
  margin-bottom:0;
  padding-bottom:15rem;
  /* margin-top:0.5rem; */
}

.sidepanel-questions::-webkit-scrollbar {
  width: 5px;
}

.sidepanel-questions::-webkit-scrollbar-track {
  border-radius: 4px;
  background:#331f37;
}

.sidepanel-questions::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background: #ff455d;
}

.question-display > div {
  border-left: 1px solid #ff455d;
  padding:0.5rem 0 0.5rem 2rem;
  
}

.question-heading {
  color:#331f37;
}



.question-subheading{
  font-weight:normal;
  font-family: 'PhoenixSans-Regular',
  sans-serif;
  line-height:1.4;
}

.question-avg{
  font-weight:bold;
  font-family: 'PhoenixSans-Bold',
  sans-serif;
  line-height:1.4;
}
@media only screen and (max-width: 768px) {
  .question-heading {
    font-size: 1.2rem;
  }

  .question-subheading, .question-avg{
    font-size:1rem;
  }

}

.sidepanel-question-heading {
  font-family: 'PhoenixSans-Bold',
  sans-serif;
  margin-top:0;
  padding: 0 0.5rem 1rem;
  position:relative;
  /* width:70%; */
  /* width:240px; */
  /* width:100%; */
  cursor: pointer;
}

.question-button-container {
  width:220px;
  margin-bottom:1rem;
}

.sidepanel-question-heading {
  color:#ffffff90;
}

.sidepanel-question-heading.active {
  color:#ffffff;
}

.sidepanel-question-heading + .question-button-container {
display:none;
}

.sidepanel-question-heading.active + .question-button-container {
display:flex;
flex-direction: column;
gap:1rem;
margin-bottom:2rem;
}

/* .sidepanel-question-heading.active {
  font-family: 'PhoenixSans-Regular',
  sans-serif;
  padding: 1rem;
  border-bottom: 4px solid #ff455d;
} */

.sidepanel-question-heading::after {
  content: '';
  position: absolute;
  width:0px;
  height:4px;
  background-color:#ff455d;
  left:0;
  bottom:0;
  transform-origin: left;
  transition: width 0.4s ease-out;
  ;
}

.sidepanel-question-heading:hover::after {
  width:220px;
}

.sidepanel-question-heading.active::after {
  content: '';
  position: absolute;
  width:220px;
  height:4px;
  background-color:#ff455d;
  left:0;
  bottom:0;
}

.question-button {
  font-weight:bold;
  font-size:1.2rem;
  border-radius: 4px;
  border: 2px solid #ff455d;
  background-color: #331f37;
  color: white;
  padding:0.75rem 0.75rem;
  font-family: 'PhoenixSans-Regular',
    sans-serif;
    font-size: 1.05rem;
  -webkit-transition: border-radius 0.2s;
    transition: border-radius 0.2s;
  
}
.question-button.active {
  background-color:#ff455d;
  font-weight:bold;
  font-family: 'PhoenixSans-Bold',
  sans-serif;
}

.question-button:hover {
  cursor:pointer;
  border-bottom-right-radius: 30px;
}

/* tooltip */
.tooltip-location {
  font-family: 'PhoenixSans-Medium',
  sans-serif;
  font-size: 1rem;
}

.tooltip-value {
  font-family: 'PhoenixSans-Bold',
  sans-serif;
  font-size: 1.4rem;
}

/* Zoom buttons */
.zoom-controls {
  position: absolute;
  right: 24px;
  top: 24px;
  z-index:100;
}

.zoom-button, .info-button {
  width:50px;
  height:50px;
  cursor: pointer;
  margin-bottom:8px;
}

.zoom-button .cls-1{fill:#331f37;}
.zoom-button .cls-2{fill:#e36877;} 
.zoom-button:hover .cls-2{fill:#e88491;} 

.zoom-button:active .cls-1{fill:#e88491;} 
.zoom-button:active .cls-2{fill:#331f37;} 

.info-button .cls-1{fill:#331f37;}
.info-button .cls-2{fill:#e36877;} 
.info-button:hover .cls-2{fill:#e88491;} 
.info-button.active .cls-1{fill:#e88491;}
.info-button.active .cls-2{fill:#331f37;} 
.info-button.active:hover .cls-2{fill:#331f37;} 

#tooltip.closed {
  margin-left: -250px;
}

.fade-out{
  opacity:0;
  width:0!important;
  min-width:0%!important;
  height:fit-content;
  transition: width 0.5s 0.5s, height 0.5s 0.5s, opacity  0.5s;

}
.fade-in{
  opacity:1;
  width:100%;
  height:fit-content;
  transition: width 0.5s, height 0.5s, opacity 0.5s 0.5s;

}